import { api } from '.';
import { OFFER_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
});

/**
 *
 * @param {string} offerId
 * @return {Promise<{Offer}>}
 */
export async function getOfferById(offerId) {
  const partialUrl = api.createUrl(getResources(offerId));
  const params = new QueryParamsBuilder();
  params.addInclude(
    'currency',
    'advertiser',
    'linkedTags',
    'offerCommentCollection',
    'offerPublisherCollection',
    'offerPostEventCollection',
    'offerFraud',
    'creativeHtmlCollection',
    'creativeDeeplinkCollection',
    'creativeTextlinkCollection',
    'creativeBannerCollection',
    'creativeFeedCollection',
    'campaign'
  );
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
