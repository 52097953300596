import { api } from '.';
import { OFFER_RESOURCE, PUBLISHER_RESOURCE, STATUS_RESOURCE } from '@/services/keys';

export const getResources = (offerId, publisherId) => ({
  [OFFER_RESOURCE]: offerId,
  [PUBLISHER_RESOURCE]: publisherId,
  [STATUS_RESOURCE]: null,
});

/**
 *
 * @param {string} offerId
 * @param {Publisher} publisher
 * @returns {Promise.<string>}
 */
export async function updatePublisherStatus(offerId, publisher) {
  const partialUrl = api.createUrl(getResources(offerId, publisher.id));
  const { data } = await api.update(partialUrl, { status: publisher.toggle });
  return data.id;
}
