import { api } from '.';
import { OFFER_RESOURCE, COMMENTS_RESOURCE } from '@/services/keys';

/**
 *
 * @param {string} offerId
 * @param {string} commentId comment id
 * @returns {Promise<object>}
 */
export async function deleteOfferComment(offerId, commentId) {
  const partialUrl = api.createUrl({
    [OFFER_RESOURCE]: offerId,
    [COMMENTS_RESOURCE]: commentId,
  });
  const { data } = await api.delete(partialUrl);
  return data;
}
