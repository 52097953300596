import { api } from '..';

/**
 *
 * @param {Object} resources
 * @param {Creative} creative
 * @returns {Promise.<object>}
 */
export async function create(resources, creative) {
  const partialUrl = api.createUrl(resources);
  const { data } = await api.create(partialUrl, creative.payload());
  return data;
}
