import { CREATIVE_HTML_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import { remove as removeShared } from '../shared/remove';

/**
 *
 * @param {String} offerId
 * @param {String} creativeId
 * @returns {Promise.<data:string>}
 */
export function remove(offerId, creativeId) {
  return removeShared({
    [OFFER_RESOURCE]: offerId,
    [CREATIVE_HTML_RESOURCE]: creativeId,
  });
}
