/// <reference path="../../../../../../model/modules/ecommerce/offer/creative/creativeDeeplink/CreativeDeeplink.js" />

import { CREATIVE_TEXTLINK_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import { create as createShared } from '@/services/modules/ecommerce/offer/creatives/shared/create';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_TEXTLINK_RESOURCE]: undefined,
});

/**
 *
 * @param {String} offerId
 * @param {CreativeTextlink} creative
 * @returns {Promise<{data:string}>}
 */
export async function create(offerId, creative) {
  const data = await createShared(getResources(offerId), creative);
  return new ResponseBuilder(data.creativeTextlink.id);
}
