import { create } from './create';
import { remove } from './remove';
import { update } from './update';
import { ecommerce } from '@/services/modules/ecommerce';
import { getPublisherTrackerLink } from '@/services/modules/ecommerce/offer/creatives/deeplink/getPublisherTrackerLink';
import { updateUtm } from './updateUtm';

export const api = ecommerce;

export const deeplink = { create, remove, update, getPublisherTrackerLink, updateUtm };
