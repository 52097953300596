import { api } from '..';
import { OFFER_RESOURCE, UTM_DEEPLINK_RESOURCE } from '@/services/keys';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [UTM_DEEPLINK_RESOURCE]: null,
});

/**
 * @param {string} offerId
 * @param {utm} utm
 * @returns {Promise.<object>}
 */
export function updateUtm(offerId, utm) {
  const partialUrl = api.createUrl(getResources(offerId));
  return api.update(partialUrl, { utm });
}
