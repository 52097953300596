import { CREATIVE_FEED_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import { remove as removeShared } from '@/services/modules/ecommerce/offer/creatives/shared/remove';

/**
 *
 * @param {String} offerId
 * @param {String} creativeId
 * @returns {Promise.<data:string>}
 */
export function remove(offerId, creativeId) {
  return removeShared({
    [OFFER_RESOURCE]: offerId,
    [CREATIVE_FEED_RESOURCE]: creativeId,
  });
}
