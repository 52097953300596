/// <reference path="../../../../entities/ecommerce/Offer.js" />

import { api } from '.';
import { OFFER_RESOURCE } from '@/services/keys';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
});

/**
 *
 * @param {Offer} offer
 * @returns {Promise.<object>}
 */
export async function updateOffer(offer) {
  const partialUrl = api.createUrl(getResources(offer.id));
  return await api.update(partialUrl, offer.payload());
}
