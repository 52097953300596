import { CREATIVE_TEXTLINK_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import { update as updateShared } from '@/services/modules/ecommerce/offer/creatives/shared/update';

export const getResources = (offerId, creativeId) => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_TEXTLINK_RESOURCE]: creativeId,
});

/**
 *
 * @param {String} offerId
 * @param {CreativeTextlink} creative
 */
export function update(offerId, creative) {
  return updateShared(getResources(offerId, creative.id), creative);
}
