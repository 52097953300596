import { CREATIVE_FEED_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import { create as createShared } from '../shared/create';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_FEED_RESOURCE]: undefined,
});

export async function create(offerId, creative) {
  const { creativeFeed } = await createShared(getResources(offerId), creative);
  return creativeFeed;
}
