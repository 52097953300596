import { api } from '@/services/modules/ecommerce/offer/creatives';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

/**
 *
 * @param {Object} resources
 * @param {Creative} creative
 * @returns {Promise.<{data:object[],meta:null}>}
 */
export async function getPublisherTrackerLinkShared(resources, creative) {
  const partialUrl = api.createUrl(resources);
  const {
    data: { code },
  } = await api.create(partialUrl, creative.payloadPublisherLink());
  return new ResponseBuilder(code).build();
}
