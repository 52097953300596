import { CREATIVE_FEED_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import { update as updateShared } from '../shared/update';

export const getResources = (offerId, creativeId) => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_FEED_RESOURCE]: creativeId,
});

export async function update(offerId, creative) {
  const { creativeFeed } = await updateShared(getResources(offerId, creative.id), creative);
  return creativeFeed;
}
