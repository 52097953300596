import { html } from './html/index.js';
import { deeplink } from './deeplink/index.js';
import { ecommerce } from '@/services/modules/ecommerce';
import { textlink } from '@/services/modules/ecommerce/offer/creatives/textlink';
import { banner } from '@/services/modules/ecommerce/offer/creatives/banner';
import { feed } from '@/services/modules/ecommerce/offer/creatives/feed';

export const api = ecommerce;

export const creatives = { banner, html, deeplink, textlink, feed };
