import { api } from '.';
import { OFFER_RESOURCE, PUBLISHER_RESOURCE } from '@/services/keys';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [PUBLISHER_RESOURCE]: null,
});

/**
 *
 * @param {string} offerId
 * @param {Publisher} publisher
 * @returns {Promise.<string>}
 */
export async function addPublisher(offerId, publisher) {
  const partialUrl = api.createUrl(getResources(offerId));
  const { data } = await api.create(partialUrl, publisher.payload());
  return data.id;
}
