import { CREATIVE_HTML_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import { create as createShared } from '../shared/create';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_HTML_RESOURCE]: undefined,
});

/**
 *
 * @param {String} offerId
 * @param {CreativeHtml} creative
 * @returns {Promise.<data:string>}
 */
export async function create(offerId, creative) {
  const data = await createShared(getResources(offerId), creative);
  return new ResponseBuilder(data.creativeHtml.id);
}
