import { api } from '..';

/**
 *
 * @param {Object} resources
 * @returns {Promise<{object}>}
 */
export async function remove(resources) {
  const partialUrl = api.createUrl(resources);
  const { data } = await api.delete(partialUrl);
  return data;
}
