import { api } from '..';

/**
 *
 * @param {Object} resources
 * @param {Creative} creative
 * @returns {Promise.<object>}
 */
export async function update(resources, creative) {
  const partialUrl = api.createUrl(resources);
  return await api.update(partialUrl, creative.payload());
}
