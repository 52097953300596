import { api } from '.';
import { OFFER_RESOURCE, HISTORY_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [HISTORY_RESOURCE]: null,
});

/**
 *
 * @param {string} offerId
 * @return {Promise<{data:Offer[],meta:EndpointMeta}>}
 */
export async function getOfferHistory(offerId) {
  const partialUrl = api.createUrl(getResources(offerId));
  const params = new QueryParamsBuilder();
  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
