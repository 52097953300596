import { api } from '.';
import { OFFER_RESOURCE, COMMENTS_RESOURCE } from '@/services/keys';

/**
 *
 * @param {String} offerId
 * @param {CommentShared} comment comment
 * @returns {Promise<object>}
 */
export async function updateOfferComment(offerId, comment) {
  const partialUrl = api.createUrl({
    [OFFER_RESOURCE]: offerId,
    [COMMENTS_RESOURCE]: comment.id,
  });
  const { data } = await api.update(partialUrl, comment.payload());
  return data;
}
