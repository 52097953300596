import { api } from '@/services/modules/ecommerce/offer/creatives';
import { CREATIVE_BANNER_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = (offerId, creativeId) => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_BANNER_RESOURCE]: creativeId,
});

/**
 *
 * @param {String} offerId
 * @param {File} file
 * @param {String} creativeId
 * @returns {Promise.<{data:string,meta:null}>}
 */
export async function createOrUpdate(offerId, file, creativeId = undefined) {
  const partialUrl = api.createUrl(getResources(offerId, creativeId));

  const formData = new FormData();
  formData.append('file', file);

  const { data } = await api.create(partialUrl, formData);
  return new ResponseBuilder(data.creativeBanner.id).build();
}
