import { ecommerce } from '..';
import { addPublisher } from './addPublisher';
import { updatePublisher } from './updatePublisher';
import { createOffer } from './createOffer';
import { createOfferComment } from './createOfferComment';
import { creatives } from './creatives';
import { deleteOfferComment } from './deleteOfferComment';
import { getOfferById } from './getOfferById';
import { getOfferHistory } from './getOfferHistory';
import { getOffers, getOffersWithoutIncludes } from './getOffers';
import { updateOffer } from './updateOffer';
import { updateOfferComment } from './updateOfferComment';
import { updatePublisherStatus } from './updatePublisherStatus';
import { updateTarget } from './updateTarget';

export const api = ecommerce;

export {
  addPublisher,
  updatePublisher,
  createOffer,
  createOfferComment,
  creatives,
  deleteOfferComment,
  getOfferById,
  getOfferHistory,
  getOffers,
  getOffersWithoutIncludes,
  updateOffer,
  updateOfferComment,
  updatePublisherStatus,
  updateTarget,
};
