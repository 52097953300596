import { CREATIVE_DEEPLINK_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import { update as updateShared } from '@/services/modules/ecommerce/offer/creatives/shared/update';

export const getResources = (offerId, creativeId) => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_DEEPLINK_RESOURCE]: creativeId,
});

/**
 *
 * @param {String} offerId
 * @param {CreativeDeeplink} creative
 * @returns {Promise.<object>}
 */
export function update(offerId, creative) {
  return updateShared(getResources(offerId, creative.id), creative);
}
