/// <reference path="../../../../entities/ecommerce/Offer.js" />

import { api } from '.';
import { OFFER_RESOURCE } from '@/services/keys';

export const getResources = () => ({
  [OFFER_RESOURCE]: undefined,
});

/**
 *
 * @param {Offer} offer
 * @returns {Promise<string>}
 */
export async function createOffer(offer, clientId = null) {
  const partialUrl = api.createUrl(getResources());
  const offerFormated = offer.payload();
  if (clientId) offerFormated.clientId = clientId;
  const {
    data: { offer: id },
  } = await api.create(partialUrl, offerFormated);
  return id;
}
