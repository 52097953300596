import { api } from '@/services/modules/ecommerce/offer/creatives';
import { CREATIVE_BANNER_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = (offerId, creativeId) => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_BANNER_RESOURCE]: creativeId,
});

/**
 *
 * @param {String} offerId
 * @returns {Promise.<{data:object,meta:null}>}
 */
export async function get(offerId, creativeId) {
  const partialUrl = api.createUrl(getResources(offerId, creativeId));

  const { data } = await api.get(partialUrl);
  return new ResponseBuilder(data).build();
}
