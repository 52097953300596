/// <reference path="../../../../entities/ecommerce/Offer.js" />

import { api } from '.';
import { OFFER_RESOURCE, TARGET_RESOURCE } from '@/services/keys';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [TARGET_RESOURCE]: undefined,
});

/**
 *
 * @param {String} offerId
 * @param {Target} target
 * @returns {Promise.<object>}
 */
export async function updateTarget(offerId, target) {
  const partialUrl = api.createUrl(getResources(offerId));
  return await api.update(partialUrl, target);
}
