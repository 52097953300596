import { api } from '.';
import { OFFER_RESOURCE, PUBLISHER_RESOURCE } from '@/services/keys';

export const getResources = (offerId, publisherId) => ({
  [OFFER_RESOURCE]: offerId,
  [PUBLISHER_RESOURCE]: publisherId,
});

/**
 *
 * @param {string} offerId
 * @param {Publisher} publisher
 * @returns {Promise.<string>}
 */
export function updatePublisher(offerId, publisher, offerPublisherId) {
  const partialUrl = api.createUrl(getResources(offerId, offerPublisherId));
  return api.update(partialUrl, publisher.payload());
}
