import { CREATIVE_HTML_RESOURCE, OFFER_RESOURCE } from '@/services/keys';
import { update as updateShared } from '../shared/update';

export const getResources = (offerId, creativeId) => ({
  [OFFER_RESOURCE]: offerId,
  [CREATIVE_HTML_RESOURCE]: creativeId,
});

/**
 *
 * @param {String} offerId
 * @param {CreativeHtml} creative
 * @returns {Promise.<data:string>}
 */
export function update(offerId, creative) {
  return updateShared(getResources(offerId, creative.id), creative);
}
